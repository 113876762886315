import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [layoutShowHeader, setLayoutShowHeader] = useState(true);
  const [layoutShowFooter, setLayoutShowFooter] = useState(true);

  return <AppContext.Provider value={{ layoutShowHeader, layoutShowFooter, setLayoutShowHeader, setLayoutShowFooter }}>{children}</AppContext.Provider>;
};

export default AppContext;
