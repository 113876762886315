import React from "react";
import Navigation from "./Navigation";

const Header = (props) => {
  return (
    <header id="header">
      <Navigation />
    </header>
  );
};

export default Header;
