import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

// @see https://github.com/sbardian/gatsby-plugin-breadcrumb/blob/develop/src/components/auto-gen-crumb.js
// @see https://getjuicy.co.uk/news/building-a-custom-gatsby-breadcrumb-component

const Breadcrumb = ({ breadcrumb }) => {
  const elementsNew = [];
  breadcrumb.crumbs.forEach((item) => {
    const newlast = item.crumbLabel.toLowerCase().replace(/-/g, " ");
    elementsNew.push({ pathname: item.pathname, crumbLabel: newlast });
  });

  if (elementsNew.length === 1 && elementsNew[0].pathname === "/") {
    return <></>;
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {elementsNew.map((value, i) => {
          return (
            <li key={i} className={`breadcrumb-item${i === elementsNew.length - 1 ? ` active` : ``}`} aria-current="page">
              <Link key={i} to={`${value.pathname}`}>
                {i === 0 && value.crumbLabel === "home" ? <i className="bi bi-house"></i> : value.crumbLabel}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
