import { Helmet } from "gatsby-plugin-react-i18next";
import React from "react";

const GenericMetaTags = (props) => {
  return (
    <Helmet>
      {/* // TODO not working @see https://github.com/nfl/react-helmet/issues/341 */}
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </Helmet>
  );
};

export default GenericMetaTags;
