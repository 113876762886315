import React from "react";
import PropTypes from "prop-types";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import LogoComExpertiseStore from "../img/logos/logo-comexpertise-store-bw.svg";

const Navigation = ({ showHome }) => {
  const { t } = useTranslation();

  return (
    <nav className="comx-menu navbar navbar-expand-lg navbar-dark bg-primary">
      <Link className="navbar-brand" to="/">
        <img className="img-fluid comx-logo" width="120" height="57" src={LogoComExpertiseStore} alt={t("ComExpertise.store")} />
      </Link>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label={t("Toggle navigation")}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          {showHome === true && (
            <li className="nav-item comx-homepage">
              <Link className="nav-link" to="/">
                <Trans>Accueil</Trans>
              </Link>
            </li>
          )}

          <li className="nav-item">
            <a className="nav-link text-success" href={`mailto:${process.env.GATSBY_EMAIL_SUPPORT}?subject=${t("[PrestaShop] Demande d'intervention/assistance technique prioritaire")}`} title={t("Assistance et support technique prioritaire (sous 6H) pour PrestaShop")} target="_blank" rel="noreferrer">
              <Trans>
                <b>Assistance technique</b>
                <br />
                PrestaShop 7j/7
              </Trans>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href={`mailto:${process.env.GATSBY_EMAIL_GENERAL}?subject=${t("[PrestaShop] Demande de devis")}`} title={t("Obtenir un devis pour le développement d'un module ou d'un thème personnalisé pour PrestaShop")} target="_blank" rel="noreferrer">
              <Trans>
                <b>Développement</b>
                <br />
                Modules/Thèmes <span className="sr-only">PrestaShop</span>
              </Trans>
            </a>
          </li>

          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" onClick={(event) => event.preventDefault()} to="/modules/prestashop/" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={t("Modules PrestaShop by ComExpertise")}>
              <Trans>
                Modules
                <br />
                PrestaShop
              </Trans>
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <Link className="dropdown-item" to="/modules/prestashop/facturation.pro-monae-matpe">
                Facturation.pro
              </Link>
              <Link className="dropdown-item" to="/modules/prestashop/payments-access-restrictions">
                Payments Access
              </Link>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="https://support.comexpertise.com" target="_blank" title={t("Consulter mes tickets de support technique et d'interventions")} rel="noreferrer">
              <Trans>
                <b>Suivre</b>
                <br />
                mes tickets
              </Trans>
            </a>
          </li>
        </ul>

        <form className="form-inline">
          <a className="btn btn-outline-info btn-md text-left" type="button" href={`mailto:${process.env.GATSBY_EMAIL_GENERAL}`} title={t("Contactez-nous par e-mail")} target="_blank" rel="noreferrer">
            <span className="d-flex flex-row">
              <span className="d-block mr-3">
                <i className="bi bi-chat-right-dots-fill" style={{ fontSize: "2em" }}></i>
              </span>
              <small className="d-block">
                <span style={{ fontSize: "larger" }}>
                  <Trans>contact</Trans>
                </span>
                <br />
                @comexpertise.com
              </small>{" "}
            </span>
          </a>
        </form>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  showHome: PropTypes.bool.isRequired,
};

Navigation.defaultProps = {
  showHome: false,
};

export default Navigation;
