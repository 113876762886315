import React, { useContext } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import GenericMetaTags from "./GenericMetaTags";
import AppContext from "./AppContext";
import Footer from "./Footer";
import MyBreadcrumb from "./Breadcrumb";
import { languages } from "../../languages";
import BackgroundImage from "gatsby-background-image";

const Layout = ({ data, children, pageContext, location }) => {
  const {
    i18n: { language, originalPath },
  } = pageContext;
  const { imageBgPage } = data || {};
  const { layoutShowHeader, layoutShowFooter } = useContext(AppContext);
  const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ");
  const containerClasses = typeof pageContext.containerClasses === "undefined" ? "my-5" : pageContext.containerClasses;

  const showBreadcrumb = () => {
    const pathname = location.pathname.toLowerCase().replace(/\/$/, "");
    const excludesPaths = ["/", "/checkout/canceled", "/checkout/success"];

    for (const lng of languages) {
      for (const path of excludesPaths) {
        if (`/${path}` === pathname || `/${lng}` === pathname || `/${lng}${path}` === pathname || path === pathname) {
          return false;
        }
      }
    }

    return true;
  };
  const WrapBgImage = ({ children }) => {
    if (!imageBgPage) {
      return <>{children}</>;
    }

    return (
      <BackgroundImage style={{ backgroundColor: "transparent", backgroundAttachment: "fixed", backgroundPosition: "top center", backgroundSize: "cover" }} Tag="div" fluid={imageBgPage.childImageSharp.fluid}>
        {children}
      </BackgroundImage>
    );
  };

  return (
    <WrapBgImage>
      <GenericMetaTags />

      <div className="layout-wrapper">
        {layoutShowHeader === true && <Header />}

        <div className="layout-body-wrapper">
          {showBreadcrumb() === true && <MyBreadcrumb breadcrumb={pageContext.breadcrumb} crumbLabel={customCrumbLabel} />}

          <main className={containerClasses} id="container">
            {children}
          </main>
        </div>

        {layoutShowFooter === true && <Footer currentLanguage={language} currentPath={originalPath} />}
      </div>
    </WrapBgImage>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  //containerClasses: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  //containerClasses: "my-5",
};

export { Layout };
