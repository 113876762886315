import "./src/styles/global.scss";
import 'jquery/dist/jquery.min.js'
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
import { wrapRootElement, wrapPageElement } from "./wrapElements";

// Called when the Gatsby browser runtime first starts.
const onClientEntry = () => { };

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
const onInitialClientRender = () => { };

export { wrapRootElement, wrapPageElement, onClientEntry, onInitialClientRender }