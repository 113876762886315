import { useStaticQuery, graphql } from "gatsby";

export const useMainLogo = () => {
    const data = useStaticQuery(
        graphql`
        query {
            mainLogo: file(relativePath: { eq: "logo-comexpertise-1-5-bw-light.svg" }) {
                footer: publicURL
            }
        }
    `);

    return data.mainLogo;
}