module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-8R7MXPPGFC","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","localeJsonNodeName":"locales","languages":["fr","en"],"defaultLanguage":"fr","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://store.comexpertise.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","excludes":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"."},"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ComExpertise.store","short_name":"ComExpertise.store","description":"Experts PrestaShop. Maintenance et développement de thèmes et modules pour PrestaShop.","start_url":"/","background_color":"#ffffff","theme_color":"#1a1a1a","display":"minimal-ui","icon":"src/img/logos/comexpertise-store-icon.svg","lang":"fr","localize":[{"start_url":"/fr/","lang":"fr","name":"ComExpertise.store","short_name":"ComExpertise.store","description":"Experts PrestaShop. Maintenance et développement de thèmes et modules pour PrestaShop."},{"start_url":"/en/","lang":"en","name":"ComExpertise.store","short_name":"ComExpertise.store","description":"PrestaShop experts. Maintenance and development of themes and modules for PrestaShop."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d54be5d479023191e7d26b84e7732545"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
