exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-checkout-canceled-jsx": () => import("./../../../src/pages/checkout/canceled.jsx" /* webpackChunkName: "component---src-pages-checkout-canceled-jsx" */),
  "component---src-pages-checkout-success-jsx": () => import("./../../../src/pages/checkout/success.jsx" /* webpackChunkName: "component---src-pages-checkout-success-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-modules-index-jsx": () => import("./../../../src/pages/modules/index.jsx" /* webpackChunkName: "component---src-pages-modules-index-jsx" */),
  "component---src-pages-modules-prestashop-index-jsx": () => import("./../../../src/pages/modules/prestashop/index.jsx" /* webpackChunkName: "component---src-pages-modules-prestashop-index-jsx" */),
  "component---src-templates-product-prestashop-module-jsx": () => import("./../../../src/templates/product/PrestashopModule.jsx" /* webpackChunkName: "component---src-templates-product-prestashop-module-jsx" */)
}

