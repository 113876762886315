import React from "react";
import { AppProvider } from "./src/components/AppContext";
import { Layout } from "./src/components/Layout";

// @see https://stackoverflow.com/questions/60946584/react-use-context-cannot-destructure-property-currentchatbotinedit-of-object
const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export { wrapRootElement, wrapPageElement };
