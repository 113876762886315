import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { languages, languagesTitlesByCode } from "../../languages";
import Flag from "react-world-flags";

const LanguageSwitcher = ({ currentLanguage, currentPath }) => {
  const { t } = useTranslation();

  // link countries and langs when the code are not the same
  const countriesByLang = {
    en: "us",
  };

  return (
    <ul className="languages list-inline text-center text-md-right text-dark">
      <li className="list-inline-item">
        <Trans>Autres langues :</Trans>
      </li>

      {languages.map((lng) => (
        <li key={lng} className="list-inline-item">
          {currentLanguage !== lng && (
            <Link to={currentPath} language={lng} title={t("Modifier la langue d'affichage")} className={`btn btn-sm mb-0 btn-light ${currentLanguage === lng ? "" : ""}`}>
              <Flag code={countriesByLang[lng] || lng} width="30" height="16" alt={lng} /> {languagesTitlesByCode[lng]}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

LanguageSwitcher.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
};

LanguageSwitcher.defaultProps = {};

export default LanguageSwitcher;
