import React from "react";
import PropTypes from "prop-types";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMainLogo } from "../hooks/use-main-logo";
import CookieConsent from "./CookieConsent";

const Footer = ({ currentLanguage, currentPath }) => {
  const { t } = useTranslation();
  const { footer: footerLogo } = useMainLogo();

  return (
    <footer id="footer" className="d-flex align-items-end justify-content-end">
      <div className="px-3 px-md-4 my-4 my-lg-5 d-md-flex justify-content-md-end">
        <div className="mt-4 mt-sm-2 mt-lg-2">
          <LanguageSwitcher currentLanguage={currentLanguage} currentPath={currentPath} />

          <p className="text-center text-md-right mb-3 mb-md-0">
            ©2021{" "}
            <Link to="/" title={t("ComExpertise, expert e-commerce PrestaShop")}>
              ComExpertise
            </Link>
            , tous droits réservés.{" "}
            {/*<Link to="/mentions-legales/">Mentions
        légales</Link>*/}
            <a title={t("Contactez-nous par e-mail")} href={`mailto:${process.env.GATSBY_EMAIL_GENERAL}`} target="_blank" rel="noreferrer">
              {process.env.GATSBY_EMAIL_GENERAL}
            </a>
          </p>

          <p className="text-center text-md-right mb-0 small">ComExpertise SARL au capital de 40 800,00 EUR, SIREN 534934542, RCS Paris B 534 934 542, TVA Intracommunautaire N° : FR39534934542</p>
        </div>
        <div className="ml-md-4 text-center text-md-right mt-4 mt-sm-2 mt-lg-2">
          <a href={process.env.GATSBY_WEBSITE_URL} target="_blank" rel="noreferrer" title={t("ESN Française - Applications métiers, APIs, solutions Web e-business Symfony / Drupal / PrestaShop et Technologies Open Source PHP")}>
            <img src={footerLogo} height="70" width="220" alt={t("Logo ComExpertise")} />
          </a>
        </div>
      </div>
      <CookieConsent />
    </footer>
  );
};

Footer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
};

Footer.defaultProps = {};

export default Footer;
