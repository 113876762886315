import React from "react";
import MainCookieConsent from "react-cookie-consent";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const CookieConsent = (props) => {
  const { t } = useTranslation();

  return (
    <MainCookieConsent buttonText={t("J'ai compris")} style={{ backgroundColor: "#010101" }} containerClasses="animate__animated animate__fadeInUp animate__delay-2s" disableButtonStyles={true} buttonClasses={"btn btn-outline-light btn-sm mr-2"}>
      <Trans>Les cookies permettent le bon fonctionnement de nos services d'audiences.</Trans>
    </MainCookieConsent>
  );
};

export default CookieConsent;
